import "./Notification.scss";
const Notification = ({ message }) => {
  return (
    <div className="notification">
      <div className="content text">{message}</div>
    </div>
  );
};

export default Notification;
