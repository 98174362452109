import "./Menu.scss";

import menuBtnImage from "../../images/menu.png";

const Menu = ({ scrollTo, changeMenuVisibility }) => {
  return (
    <>
      <div className="menu">
        <div className="menu__list">
          <p className="title" data-letter=".block__1" onClick={scrollTo}>
            about me
          </p>
          <p className="title" data-letter=".block__2" onClick={scrollTo}>
            skills
          </p>
          <p className="title" data-letter=".block__3" onClick={scrollTo}>
            projects
          </p>
          <p className="title" data-letter=".block__5" onClick={scrollTo}>
            contacts
          </p>
        </div>
      </div>
      <div className="content__space menu__btn">
        {/* <div className="title">{"</>"}</div> */}
        {/* <div className="title">{"</>"}</div> */}

        <button className="menu__icon" onClick={changeMenuVisibility}>
          <img src={menuBtnImage} alt="" />
        </button>
      </div>
    </>
  );
};

export default Menu;
