import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addComment = createAsyncThunk("add/comment", async (params) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_WEBSITE_NAME}/comments`,
    params,
  );
  console.log(12345);
  console.log(data);
  return data.data;
});

export const fetchComments = createAsyncThunk(
  "get/comments",
  async (params) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_WEBSITE_NAME}/comments/get-all`,
    );
    return data.data;
  },
);

const initialState = {
  data: [],
  status: "loading",
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchComments.pending]: (state) => {
      state.data = [];
      state.status = "loading";
    },
    [fetchComments.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "loaded";
    },
    [fetchComments.rejected]: (state) => {
      state.data = [];
      state.status = "error";
    },

    [addComment.fulfilled]: (state, action) => {
      console.log(state.data);
      state.data = [action.payload, ...state.data];
    },
  },
});

export const commentsReducer = commentsSlice.reducer;
