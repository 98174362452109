import { useState, useEffect } from "react";
import "./ProgressBar.scss";

const ProgressBar = () => {
  const [barWidth, setBarWidth] = useState(0);
  const wrapper = document.querySelector(".wrapper");

  useEffect(() => {
    const changeProgressBarWidth = () => {
      const width =
        (Math.abs(wrapper.getBoundingClientRect().top) /
          (wrapper.clientHeight - window.innerHeight)) *
        100;
      setBarWidth((barWidth) => width);
    };
    document.addEventListener("scroll", changeProgressBarWidth);
    return () => {
      document.removeEventListener("scroll", changeProgressBarWidth);
    };
  }, []);
  return (
    <div className="progress__bar">
      <div
        className="progress__bar__content"
        style={{ width: `${barWidth}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
