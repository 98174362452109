import "./Block2.scss";

const Block2 = () => {
  return (
    <div className="block__2 content__space">
      <div className="skills__title title block__title">Skills</div>
      <div className="skills__disriptions content__block">
        <div className="skill__line">
          <div className="title">Frontend</div>
          <div className="skills">
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=html"
                className="skill__image"
                alt="html"
              />
              <div className="text">Html</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=css"
                className="skill__image"
                alt="css"
              />
              <div className="text">Css</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=scss"
                className="skill__image"
                alt="Scss"
              />
              <div className="text">Scss</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=js"
                className="skill__image"
                alt="JS"
              />
              <div className="text">JavaScript</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=ts"
                className="skill__image"
                alt="TS"
              />
              <div className="text">TypeScript</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=react"
                className="skill__image"
                alt="React"
              />
              <div className="text">ReactJS</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=redux"
                className="skill__image"
                alt="Redux"
              />
              <div className="text">Redux</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=next"
                className="skill__image"
                alt="NextJS"
              />
              <div className="text">NextJS</div>
            </div>
          </div>
        </div>
        <div className="skill__line">
          <div className="title">Backend</div>
          <div className="skills">
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=nodejs"
                className="skill__image"
                title="nodejs"
                alt="nodejs"
              />
              <div className="text">nodeJs</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=python"
                className="skill__image"
                title="python"
                alt="python"
              />
              <div className="text">Python</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=django"
                className="skill__image"
                title="django"
                alt="django"
              />
              <div className="text">Django</div>
            </div>

            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=nest"
                className="skill__image"
                title="nestjs"
                alt="nestjs"
              />
              <div className="text">NestJS</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=mysql"
                className="skill__image"
                title="mysql"
                alt="mysql"
              />
              <div className="text">MySQL</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=sqlite"
                className="skill__image"
                title="sqlite"
                alt="sqlite"
              />
              <div className="text">SQLite</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=mongo"
                className="skill__image"
                title="mongodb"
                alt="mongodb"
              />
              <div className="text">Mongo</div>
            </div>

            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=postgres"
                className="skill__image"
                title="postgres"
                alt="postgres"
              />
              <div className="text">Postgres</div>
            </div>
          </div>
        </div>

        <div className="skill__line">
          <div className="title">Tools</div>
          <div className="skills">
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=docker"
                className="skill__image"
                title="docker"
                alt="docker"
              />
              <div className="text">Docker</div>
            </div>

            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=nginx"
                className="skill__image"
                alt="nginx"
              />
              <div className="text">Nginx</div>
            </div>

            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=git"
                className="skill__image"
                alt="git"
              />
              <div className="text">Git</div>
            </div>
          </div>
        </div>
        <div className="skill__line">
          <div className="title">Other</div>
          <div className="skills">
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=c"
                className="skill__image"
                alt="C"
              />
              <div className="text">C</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=cpp"
                className="skill__image"
                alt="C++"
              />
              <div className="text">C++</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=linux"
                className="skill__image"
                alt="nodejs"
              />
              <div className="text">Linux</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=arduino"
                className="skill__image"
                alt="nodejs"
              />
              <div className="text">Arduino</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=ps"
                className="skill__image"
                alt="Photoshop"
              />
              <div className="text">Photoshop</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=pr"
                className="skill__image"
                alt="Premiere"
              />
              <div className="text">Premiere</div>
            </div>
            <div className="skill">
              <img
                src="https://skillicons.dev/icons?i=neovim"
                className="skill__image"
                alt="Neovim"
              />
              <div className="text">Neovim</div>
            </div>
          </div>
        </div>
        {/* <div className="skill__div">
          <div className="content__block skill__block frontend">
            <div className="skill__title subtitle">Frontend</div>
            <ol className="skills__list">
              <li className="skill text">html</li>
              <li className="skill text">css</li>
              <li className="skill text">SCSS</li>
              <li className="skill text">javascript</li>
              <li className="skill text">React</li>
              <li className="skill text">redux</li>
            </ol>
          </div>
        </div>
        <div className="skill__div">
          <div className="content__block skill__block frontend">
            <div className="skill__title subtitle">Backend</div>
            <ol className="skills__list">
              <li className="skill text">Node js Express</li>
              <li className="skill text">python3 (Django)</li>
              <li className="skill text">MYSQL</li>
              <li className="skill text">SQLite</li>
              <li className="skill text">SSL</li>
              <li className="skill text">mongodb</li>
              <li className="skill text">Nginx</li>
              <li className="skill text">aiogram</li>
              <li className="skill text">API</li>
              <li className="skill text">git</li>
            </ol>
          </div>
        </div>
        <div className="skill__div">
          <div className="content__block skill__block frontend">
            <div className="skill__title subtitle">Other</div>
            <ol className="skills__list">
              <li className="skill text">Linux</li>
              <li className="skill text">Photoshop</li>
              <li className="skill text">Premiere pro</li>
              <li className="skill text">Arduino</li>
              <li className="skill text">C/C++</li>
            </ol>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Block2;
