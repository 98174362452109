import "./Block4.scss";

import { useSelector } from "react-redux";

const Block4 = () => {
  return (
    <div className="block__4 content__space">
      <div className="mobile__title title block__title">Commits</div>
      <div className="commits__block content__block">
        <div className="commits__list">
          <div className="commits__list__title subtitle">
            <div className="commits__title__tab name">Repository</div>
            <div className="commits__title__tab message">Message</div>
            <div className="commits__title__tab time">Time</div>
          </div>
          <div className="commits__list__commits">
            <GetCommitsList />
          </div>
        </div>
        <div className="commits__title title">Commits</div>
      </div>
    </div>
  );
};

const GetCommitsList = () => {
  const commitsList = useSelector((store) => store.commits.data);
  return commitsList.map((commit, key) => {
    const date = commit.time.split("T")[0];
    const time = commit.time.split("T")[1].split("Z")[0].slice(0, -7);
    return (
      <div className="commit text" key={key}>
        <div className="name">{commit.repository}</div>
        <div className="message">{commit.message}</div>
        <div className="time">{`${date} ${time}`}</div>
      </div>
    );
  });
};

export default Block4;
