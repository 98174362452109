import "./Block3.scss";

import steamToolsImg from "../../images/Screenshot 2023-01-14 at 20.37.46.png";
import shortLinksImg from "../../images/Screenshot 2023-01-14 at 12.46.33.png";
import covidTgImg from "../../images/Screenshot 2023-01-14 at 20.41.47.png";

const Block3 = () => {
  return (
    <div className="block__3 content__space">
      <div className="title projects__title block__title">Projects</div>
      <div className="projects__list">
        <div className="project content__block">
          <a
            href="https://steam-tools.net-walker.com/"
            rel="noreferrer"
            target="_blank"
            className="project__photo"
          >
            <img
              className="project__image"
              src={steamToolsImg}
              alt="steamTools"
            />
          </a>
          <div className="project__discription">
            <div className="project__title subtitle">Steam tools</div>
            <div className="create__time subtext">2023 year</div>
            <div className="disctiption__text text">
              Assist service for steam users, for getting his account statistic.
              The project is implemented on react, redux and node js. For
              authorization it uses cookies and steam oauth2.
            </div>
          </div>
        </div>
        <div className="project content__block">
          <a
            href="https://short-links.net-walker.com/"
            rel="noreferrer"
            target="_blank"
            className="project__photo"
          >
            <img className="project__image" src={shortLinksImg} alt="" />
          </a>
          <div className="project__discription">
            <div className="project__title subtitle">Short links</div>
            <div className="create__time subtext">2022 year</div>
            <div className="text disctiption__text">
              The site is a simplified analogue of bit.ly and was developed as a
              training project for learning new web technologies. Implemented
              using NodeJS express, mongoDB, react, redux.
            </div>
          </div>
        </div>
        <div className="project content__block">
          <a
            href="https://t.me/information_covid_19_bot"
            target="_blank"
            rel="noreferrer"
            className="project__photo"
          >
            <img className="project__image" src={covidTgImg} alt="" />
          </a>
          <div className="project__discription">
            <div className="project__title subtitle">Covid telegram bot</div>
            <div className="create__time subtext">2020 year</div>
            <div className="disctiption__text text">
              One of my first projects was implemented in 2020, when I was just
              starting out in development. The project was relevant at that
              time. Bot in the programming language python using the database
              sqlite and presents up-to-date information about covid-19 disease
              statistics in real time . It uses data from the worldometers site
              and is obtained with the help of a parser.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block3;
