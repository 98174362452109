import { configureStore } from "@reduxjs/toolkit";

import { commitsReducer } from "./slices/commits";
import { commentsReducer } from "./slices/comments";

const store = configureStore({
  reducer: {
    commits: commitsReducer,
    comments: commentsReducer,
  },
});

export default store;
