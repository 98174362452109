import LoadPlaceholder from "../../Components/LoadPlaceholder/LoadPlaceholder";
import Menu from "../../Components/Menu/Menu";
import Block1 from "../../Components/Block_1/Block1";
import Block2 from "../../Components/Block_2/Block2";
import Block3 from "../../Components/Block_3/Block3";
import Block4 from "../../Components/Block_4/Block4";
import Block5 from "../../Components/Block_5/Block5";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchComments } from "../../redux/slices/comments";
import { fetchCommits } from "../../redux/slices/commits";

const MainPage = ({
  menuVisibility,
  setMenuVisibility,
  changeMenuVisibility,
}) => {
  const dispatch = useDispatch();

  const commentsStatus = useSelector((store) => store.commits.status);
  const commitsStatus = useSelector((store) => store.commits.status);

  useEffect(() => {
    dispatch(fetchComments());
    dispatch(fetchCommits());
  }, []);

  const scrollTo = (e) => {
    const scrollBlock = e.target.dataset.letter;
    let elementPosition = document.querySelector(scrollBlock).offsetTop;
    if (menuVisibility) {
      setMenuVisibility((menuVisibility) => false);
      setTimeout(() => {
        window.scroll({
          top: elementPosition,
          behavior: "smooth",
        });
      }, 700);
    } else {
      window.scroll({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const isLoading = commentsStatus === "loading" || commitsStatus === "loading";
  const isError = commentsStatus === "error" || commitsStatus === "error";

  return (
    <>
      {isLoading || isError ? (
        <LoadPlaceholder isError={isError} isLoading={isLoading} />
      ) : (
        <>
          <Menu
            changeMenuVisibility={changeMenuVisibility}
            scrollTo={scrollTo}
          />
          <Block1 />
          <Block2 />
          <Block3 />
          <Block4 />
          <Block5 menuVisibility={menuVisibility} />
          <ProgressBar />
        </>
      )}
    </>
  );
};

export default MainPage;
