import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCommits = createAsyncThunk("/commits/get-all", async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_WEBSITE_NAME}/commits/get-all`,
  );
  return data.data;
});

const initialState = {
  data: null,
  status: "loading",
};

const commitsSlice = createSlice({
  name: "commits",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCommits.pending]: (state) => {
      state.data = null;
      state.status = "loading";
    },
    [fetchCommits.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "loaded";
    },
    [fetchCommits.rejected]: (state) => {
      state.data = null;
      state.status = "error";
    },
  },
});

export const commitsReducer = commitsSlice.reducer;
