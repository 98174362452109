import "./Block5.scss";

import vkImg from "../../images/vk.png";
import instagramImg from "../../images/instagram.png";
import telegramImg from "../../images/telegram.png";
import linkedinImg from "../../images/linkedin.png";
import emailImg from "../../images/mail.png";
import githubImg from "../../images/github.png";

import CommentsBlock from "../CommentsBlock/CommentsBlock";

const Block5 = ({ menuVisibility }) => {
  return (
    <div className="block__5 content__space">
      <div className="contacts__div">
        <div className="contacts content__block">
          <div className="creator__title title">Created by net-walker</div>
          <div className="contacts__data">
            <Contacts />
            <div className="contacts__text">
              <div className="time__create information__space">
                <div className="subtitle time__create__title">Time create</div>

                <li className="text">from 20.01.2023</li>
                <li className="text">to 15.02.2023</li>
              </div>
              <div className="text">
                <br />
                <br />
                {"<--------------"}
                <br />
                <br />
                Thinking about how to get in touch? Follow the links on the
                side. I'm always glad to meet new people {":)"}
                <br />
                <br />
                {"<--------------"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommentsBlock menuVisibility={menuVisibility} />
    </div>
  );
};

const Contacts = () => {
  return (
    <div className="contacts__images">
      <div className="contact">
        <a
          href="https://github.com/n3t-walker"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img src={githubImg} alt="github" className="social__link__image" />
        </a>
      </div>
      <div className="contact">
        <a
          href="https://www.linkedin.com/in/net-walker/"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={linkedinImg}
            alt="linkedIn"
            className="social__link__image"
          />
        </a>
      </div>
      {/* <div className="contact">
        <a
          href="https://www.codewars.com/users/net-walker"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={codewarsImg}
            alt="Codewars"
            className="social__link__image"
          />
        </a>
      </div> */}
      <div className="contact">
        <a
          href="https://vk.com/n_vdovin"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img src={vkImg} alt="vk" className="social__link__image" />
        </a>
      </div>
      <div className="contact">
        <a
          href="https://www.instagram.com/n3twalker"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={instagramImg}
            alt="instagram"
            className="social__link__image"
          />
        </a>
      </div>
      <div className="contact">
        <a
          href="mailto:nikolai.vdowin2016@yandex.ru"
          rel="noreferrer"
          className="social__link"
        >
          <img src={emailImg} alt="email" className="social__link__image" />
        </a>
      </div>
      <div className="contact">
        <a
          href="https://t.me/n3t_walker"
          className="social__link"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={telegramImg}
            alt="telegram"
            className="social__link__image"
          />
        </a>
      </div>
    </div>
  );
};

export default Block5;

// const Block5 = ({ menuVisibility }) => {
//   return (
//     <div className="block__5 content__space">
//       <div className="contacts__div">
//         <div className="contacts content__block">
//           <div className="creator__title title">Created by net-walker</div>
//           <div className="contacts__data">
//             <Contacts />
//             <div className="contacts__text">
//                 <div className="time__create information__space">
//                   <div className="subtitle time__create__title">
//                     Time create
//                   </div>

//                   <li className="text">from 20.01.2023</li>
//                   <li className="text">to 15.02.2023</li>
//                 </div>
//                 <div className="text">
//                   <br />
//                   <br />
//                   {"<--------------"}
//                   <br />
//                   <br />
//                   Thinking about how to get in touch? Follow the links on the
//                   side. I'm always glad to meet new people {":)"}
//                   <br />
//                   <br />
//                   {"<--------------"}
//                 </div>
//               </div>
//           </div>
//         </div>
//       </div>
//       <CommentsBlock menuVisibility={menuVisibility} />
//     </div>
//   );
// };

// const Contacts = () => {
//   return (
//     <div className="contacts__images">
//       <div className="contact">
//         <a
//           href="https://github.com/n3t-walker"
//           className="social__link"
//           rel="noreferrer"
//           target="_blank"
//         >
//           <img src={githubImg} alt="github" className="social__link__image" />
//         </a>
//       </div>
//       <div className="contact">
//         <a
//           href="https://www.linkedin.com/in/net-walker/"
//           className="social__link"
//           rel="noreferrer"
//           target="_blank"
//         >
//           <img
//             src={linkedinImg}
//             alt="linkedIn"
//             className="social__link__image"
//           />
//         </a>
//       </div>

//       <div className="contact">
//         <a
//           href="https://vk.com/n_vdovin"
//           className="social__link"
//           rel="noreferrer"
//           target="_blank"
//         >
//           <img src={vkImg} alt="vk" className="social__link__image" />
//         </a>
//       </div>
//       <div className="contact">
//         <a
//           href="https://www.instagram.com/n3twalker"
//           className="social__link"
//           rel="noreferrer"
//           target="_blank"
//         >
//           <img
//             src={instagramImg}
//             alt="instagram"
//             className="social__link__image"
//           />
//         </a>
//       </div>
//       <div className="contact">
//         <a
//           href="mailto:nikolai.vdowin2016@yandex.ru"
//           rel="noreferrer"
//           className="social__link"
//         >
//           <img src={emailImg} alt="email" className="social__link__image" />
//         </a>
//       </div>
//       <div className="contact">
//         <a
//           href="https://t.me/n3t_walker"
//           className="social__link"
//           rel="noreferrer"
//           target="_blank"
//         >
//           <img
//             src={telegramImg}
//             alt="telegram"
//             className="social__link__image"
//           />
//         </a>
//       </div>
//     </div>
//   );
// };

// export default Block5;
