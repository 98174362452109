import "./Block1.scss";

import avatarImage from "../../images/image.png";

const Block1 = () => {
  return (
    <div className="block__1 content__space">
      <div className="content__block about__me">
        <div className="about__me__title">
          <div className="avatar__div">
            <img src={avatarImage} alt="" className="avatar" />
          </div>
          <div className="name title">Nikolai Vdovin</div>
          <div className="nickname subtitle">net-walker</div>
        </div>
        <div className="about__me__discription text">
          Hi unknown reader{"))"} <br />
          Nice to see you on my site ;{")"} <br />
          I am developer from Moscow. <br />
          Programming for me is a hobby, which I want to do all my life. I
          started developing 3 years ago and since then I am constantly learning
          something new. I strive to be more than just a developer on one stack,
          and constantly trying new technologies and approaches. Now I'm getting
          a higher education in the field of computer scienсe
        </div>
      </div>
    </div>
  );
};

export default Block1;
