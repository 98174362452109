import "./CommentsBlock.scss";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../redux/slices/comments";

import Notification from "../Notification/Notification";

const Comments = ({ menuVisibility }) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");

  const [notification, setNotification] = useState(false);
  const [commentText, setCommentText] = useState("");

  const dispatch = useDispatch();

  const sendComment = async (e) => {
    e.preventDefault();

    const data = await dispatch(addComment({ author: name, text: text }));
    if ("error" in data) {
      if (data.error.message === "Request failed with status code 500") {
        setCommentText((commentText) => "You can write only 1 comment");
      } else {
        setCommentText((commentText) => "Add comment error");
      }
    } else {
      setCommentText((commentText) => "Comment successful added");
    }

    setNotification((notification) => true);
    setTimeout(() => {
      setNotification((notification) => false);
      setCommentText((commentText) => "");
    }, 5500);

    setName((name) => "");
    setText((text) => "");
  };

  const isBtnDisabled =
    menuVisibility ||
    name.length === 0 ||
    text.length === 0 ||
    name.trim().length === 0 ||
    name.trim().length === 0;

  return (
    <>
      {notification && <Notification message={commentText} />}
      <div className="comments__div">
        <div className="comments content__block">
          <div className="comments__title title">Comments</div>
          <div className="add__comment">
            <form className="add__comment__form">
              <input
                type="text"
                placeholder="name"
                className="input__form text"
                value={name}
                onChange={(e) => setName((name) => e.target.value)}
              />

              <input
                type="text"
                placeholder="message"
                className="input__form text"
                value={text}
                onChange={(e) => setText((text) => e.target.value)}
              />

              <button
                type="button"
                className="send__comment text"
                onClick={sendComment}
                disabled={isBtnDisabled ? "disabled" : ""}
              >
                Send
              </button>
            </form>
          </div>
          <div className="comments__list">
            <GetCommentsList />
          </div>
        </div>
      </div>
    </>
  );
};

const GetCommentsList = () => {
  const comments = useSelector((store) => store.comments.data);
  return comments.map((comment, key) => {
    const date = comment.time.split("T")[0];
    return (
      <div className="comment" key={key}>
        <div className="comment__author">
          <div className="name text">{comment.author}</div>
          <div className="time text">{date}</div>
        </div>
        <div className="comment__text text">{comment.text}</div>
      </div>
    );
  });
};

export default Comments;
