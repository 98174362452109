import errorImage from "../../images/errorImage.png";
import "./style.scss";

import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="notfound__div">
      <div className="notfound__img__div">
        <img src={errorImage} alt="error image" className="notfound__image" />
      </div>
      <div className="title not-found-title">
        Ooooops... page node found{"("}
      </div>
      <button
        className="main__page__button"
        onClick={() => {
          console.log(1);
          return navigate("/");
        }}
      >
        <div className="subtitle">Back to main page</div>
      </button>
    </div>
  );
};

export default PageNotFound;
