import "./LoadPlaceholder.scss";

import spinnerImage from "../../images/spinner.gif";
import dinoColorImage from "../../images/dinosaur (1).png";

const LoadPlaceholder = ({ isError, isLoading }) => {
  return (
    <div className="placeholder">
      {isLoading && <LoadingSpinner />}
      {isError && <ErrorLoading />}
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div className="load__placeholder">
      <img src={spinnerImage} alt="spinner" className="spinner__image" />
    </div>
  );
};

const ErrorLoading = () => {
  return (
    <div className="error__load__div">
      <div className="error__img__div">
        <img src={dinoColorImage} alt="error image" className="error__image" />
      </div>
      <div className="title">Ooops... something went wrong :{"("}</div>
      <div className="text">Try again later</div>
    </div>
  );
};

export default LoadPlaceholder;
