import "./App.scss";

import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/main_page";
import PageNotFound from "./pages/error_page";

const App = () => {
  const [menuVisibility, setMenuVisibility] = useState(false);

  const changeMenuVisibility = () => {
    setMenuVisibility((menuVisibility) => !menuVisibility);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={`wrapper ${menuVisibility ? "stop__scroll" : " "}`}>
            <MainPage
              menuVisibility={menuVisibility}
              setMenuVisibility={setMenuVisibility}
              changeMenuVisibility={changeMenuVisibility}
            />
          </div>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
